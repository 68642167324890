import { PropsWithChildren } from 'react';
import { tv } from '../../../utils/tv';
import { Icon } from '../../../theme/icon';
import { FormFieldFeedback } from './feedback';

const style = tv({
  base: `
    flex
    gap-xxs
  `
});

type Props = PropsWithChildren;

export const FormFieldFeedbackAlert = ({ children }: Props) => {
  return (
    <div className={style()}>
      <Icon icon="alert" color="warning" />
      <FormFieldFeedback>{children}</FormFieldFeedback>
    </div>
  );
};
