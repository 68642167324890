import {
  Combobox as CarrotCombobox,
  type ComboboxProps as CarrotComboboxProps
} from '@dagens/carrot';
import {
  FieldValues,
  get,
  Path,
  PathValue,
  useFormContext
} from 'react-hook-form';
import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';
import { Simplify } from 'type-fest';
import { FieldProps, useField } from '../utils/use-field';

// Fields from the Carrot component that are handled by react-hook-form
type ExcludedFields = 'error' | 'value' | 'onChange' | 'options';

export type SearchProps<
  Form extends FieldValues,
  Name extends Path<Form>,
  Value extends PathValue<Form, Name>
> = Simplify<
  Omit<CarrotComboboxProps<Value>, ExcludedFields> & {
    debounce?: number;
    onLoadingChange?: (loading: boolean) => void;
    searchFn: (
      query: string | null
    ) => Promise<Value[] | null> | Value[] | null;
  } & FieldProps<Form, Name>
>;

export const Search = <
  Form extends FieldValues,
  Name extends Path<Form>,
  Value extends PathValue<Form, Name>
>({
  name,
  required,
  validate,
  shouldUnregister,
  disabled,
  onLoadingChange,

  searchFn,
  debounce: wait,
  ...props
}: SearchProps<Form, Name, Value>) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Value[] | null>(null);
  const { getValues } = useFormContext<Form>();
  const {
    field,
    fieldState: { error }
  } = useField({
    name,
    required,
    validate,
    shouldUnregister,
    disabled
  });

  const throttledOnChange = useMemo(() => {
    return debounce(async (v: string | null) => {
      const resolvedValue = await searchFn?.(v);
      if (get(getValues(), name) === v) {
        const options = resolvedValue
          ? Array.isArray(resolvedValue)
            ? resolvedValue
            : [resolvedValue]
          : [];
        setOptions(options);
        setLoading(false);
        onLoadingChange?.(false);
      }
    }, wait ?? 0);
  }, [searchFn, field.onChange]);

  const onInputChangeInternal = useCallback(
    async (v: string | null) => {
      if (!v || v === '') {
        setLoading(false);
        field.onChange(null);
        setOptions(null);
        return;
      }

      setLoading(true);
      onLoadingChange?.(true);
      throttledOnChange.cancel();
      field.onChange(v);
      throttledOnChange(v);
    },
    [field.onChange, throttledOnChange]
  );

  return (
    <CarrotCombobox
      error={!loading && Boolean(error)}
      onInputChange={e => onInputChangeInternal(e)}
      value={field.value}
      options={loading && options?.length === 0 ? null : options}
      loading={loading}
      onChange={field.onChange}
      {...props}
    />
  );
};
