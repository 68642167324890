import { ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { ByComparator, ComparisonUtils } from '@dagens/utils';
import { tv } from '../../../utils/tv';
import { Button } from '../../button';

type FilterArgs<T> = {
  option: T;
  value: T[];
  valueToString: (value: T) => string;
  by?: ByComparator<T>;
  query: string;
  hideSelected?: boolean;
};

const filterOption = <T,>({
  option,
  value,
  valueToString,
  by,
  query,
  hideSelected
}: FilterArgs<T>) => {
  const valueAlreadySelected =
    hideSelected && value?.some(v => ComparisonUtils.equal(by, v, option));

  if (query === '') {
    return !valueAlreadySelected;
  }

  const matchesQuery = valueToString(option)
    .trim()
    .toLocaleLowerCase()
    .includes(query.trim().toLocaleLowerCase());
  return matchesQuery && !valueAlreadySelected;
};

type OptionToItemArgs<T> = {
  option: T;
  value: T[];
  valueToString: (value: T) => string;
  by?: ByComparator<T>;
};

const optionToItem = <T,>({
  option,
  value,
  valueToString,
  by
}: OptionToItemArgs<T>) => {
  return {
    value: option,
    text: valueToString(option),
    active: value?.some(v => ComparisonUtils.equal(by, v, option))
  };
};

const style = tv({
  slots: {
    options: `
      absolute
      z-10
      mt-xxs
      flex
      max-h-[220px]
      w-full
      flex-col
      overflow-y-auto
      overflow-x-hidden
      rounded
      border
      border-lightGrey
      bg-white

      empty:invisible

      focus:outline-none
    `,
    option: `
      group
      grid
    `,
    divider: `
      mx-xs
      border-lightGrey
    `
  },
  variants: {
    disabled: {
      true: {
        option: `pointer-events-none`
      }
    }
  }
});

type SingleProps<T> = {
  options: T[] | null;
  emptyOptionsText: string;
  disabledOptions?: T[];
  value: T;
  valueToString: (value: T) => string;
  by?: ByComparator<T>;
  query: string;
};

export const ComboboxCustomOptions = <T,>({
  options,
  emptyOptionsText,
  disabledOptions,
  value,
  valueToString,
  by,
  query
}: SingleProps<T>) => {
  const { options: optionsStyle, option } = style();

  const filteredOptions = options
    ?.filter(option =>
      filterOption({
        option,
        value: [value],
        valueToString,
        by,
        query
      })
    )
    .map(option =>
      optionToItem({
        option,
        value: [value],
        valueToString,
        by
      })
    );

  return (
    <ComboboxOptions className={optionsStyle()} as="ul" modal={false}>
      {filteredOptions?.length === 0 && (
        <ComboboxOption as="li" value={null} disabled>
          <Button.DropdownItem disabled>{emptyOptionsText}</Button.DropdownItem>
        </ComboboxOption>
      )}
      {filteredOptions?.map(({ active, text, value }) => {
        const disabled = disabledOptions?.some(disabledOption =>
          ComparisonUtils.equal(by, disabledOption, value)
        );
        return (
          <ComboboxOption
            key={text}
            value={value}
            as="li"
            className={option({ disabled })}
            disabled={disabled}
          >
            <Button.DropdownItem active={active} disabled={disabled}>
              {text}
            </Button.DropdownItem>
          </ComboboxOption>
        );
      })}
    </ComboboxOptions>
  );
};

type ItemMulti<T> = T & {
  children?: ItemMulti<T>[];
};

type MultiProps<T> = {
  options: ItemMulti<T>[] | null;
  emptyOptionsText: string;
  disabledOptions?: T[];
  value: T[];
  valueToString: (value: T) => string;
  by?: ByComparator<T>;
  query: string;
};

export const ComboboxCustomOptionsMulti = <T,>({
  options,
  emptyOptionsText,
  disabledOptions,
  value,
  valueToString,
  by,
  query
}: MultiProps<T>) => {
  const { options: optionsStyle } = style();
  return (
    <ComboboxOptions className={optionsStyle()} as="ul" modal={false}>
      <RecursiveOptions
        options={options}
        emptyOptionsText={emptyOptionsText}
        disabledOptions={disabledOptions}
        value={value}
        valueToString={valueToString}
        by={by}
        query={query}
      />
    </ComboboxOptions>
  );
};

const RecursiveOptions = <T,>({
  options,
  emptyOptionsText,
  disabledOptions,
  value,
  valueToString,
  by,
  query
}: MultiProps<T>) => {
  const { divider, option } = style();
  const children = options
    ?.flatMap(({ children }) => children)
    .filter(
      (option, index, array): option is ItemMulti<T> =>
        Boolean(option) &&
        array.findIndex(v => ComparisonUtils.equal(by, v, option)) === index
    );

  const filteredOptions = options
    ?.filter(option =>
      filterOption({
        option,
        value,
        valueToString,
        by,
        query,
        hideSelected: true
      })
    )
    .map(option => ({
      ...optionToItem({
        option,
        value,
        valueToString,
        by
      }),
      hasChildren: Boolean(option.children?.length)
    }))
    .sort((a, b) => {
      if (a.hasChildren && !b.hasChildren) {
        return -1;
      }
      if (!a.hasChildren && b.hasChildren) {
        return 1;
      }
      return 0;
    });

  return (
    <>
      {filteredOptions?.length === 0 && (
        <ComboboxOption as="li" value={null} disabled>
          <Button.DropdownItem disabled>{emptyOptionsText}</Button.DropdownItem>
        </ComboboxOption>
      )}
      {filteredOptions?.map(({ active, text, value }, index) => {
        const disabled = disabledOptions?.some(disabledOption =>
          ComparisonUtils.equal(by, disabledOption, value)
        );
        return (
          <ComboboxOption
            key={`${text}-${index}`}
            value={value}
            as="li"
            className={option({ disabled })}
            disabled={disabled}
          >
            <Button.DropdownItem active={active} disabled={disabled}>
              {text}
            </Button.DropdownItem>
          </ComboboxOption>
        );
      })}
      {children && children.length > 0 && (
        <>
          <hr className={divider()} />
          <RecursiveOptions
            options={children}
            emptyOptionsText={emptyOptionsText}
            disabledOptions={disabledOptions}
            value={value}
            valueToString={valueToString}
            by={by}
            query={query}
          />
        </>
      )}
    </>
  );
};
