import { ComboboxInput as HeadlessComboboxInput } from '@headlessui/react';
import { Fragment } from 'react/jsx-runtime';
import { Input } from '../../input';
import { nbsp } from '../../../utils/nbsp';

type SingleProps<T> = {
  value?: T;
  error?: boolean;
  open?: boolean;
  loading?: boolean;
  placeholder?: string;
  valueToString: (value?: T) => string;
  onChange: (value: string) => void;
};

export const ComboboxInput = <T,>({
  value,
  placeholder,
  error,
  loading,
  valueToString,
  onChange
}: SingleProps<T>) => {
  return (
    <HeadlessComboboxInput
      as={Fragment}
      displayValue={valueToString}
      onChange={event => onChange(event.target.value)}
    >
      <Input
        placeholder={placeholder ?? nbsp}
        error={error}
        value={valueToString(value)}
        onChange={() => {
          // Do nothing – handled by HeadlessComboboxInput
        }}
        after={<Input.Loader loading={loading ?? false} />}
      />
    </HeadlessComboboxInput>
  );
};
