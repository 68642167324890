import OrdersHistoryPage from '../../_common/pages/order-history/OrdersHistoryPage';
import CheckoutPage from '../../_consumer/pages/checkout-page/CheckoutPage';
import FavoritesPage from '../../_consumer/pages/favorites/FavoritesPage';
import CustomerLandingPage from '../../_consumer/pages/landing-page/CustomerLandingPage';
import OnboardingPage from '../../_consumer/pages/onboarding/OnboardingPage';
import ConsumerOrdersOverviewPage from '../../_consumer/pages/orders/ConsumerOrdersOverviewPage';
import ConsumerOrdersPage from '../../_consumer/pages/orders/ConsumerOrdersPage';
import ConsumerProductRequestPage from '../../_consumer/pages/orders/ConsumerProductRequestPage';
import ConsumerProductRequestsPage from '../../_consumer/pages/orders/ConsumerProductRequestsPage';
import ProducerListPage from '../../_consumer/pages/producer-list-page/ProducerListPage';
import ConsumerProfilePage from '../../_consumer/pages/profile/ConsumerProfilePage';
import { ConsumerLayout } from '../../_consumer/layout';
import { Routes } from '../types';
import { ProducerRedirect } from '../components/producer-redirect';
import LoginPage from '_unauthenticated/pages/login/Login';
import VerifyUserEmail from '_common/pages/VerifyUserEmail';
import SwitchRolePage from '_common/pages/switch-role-page/SwitchRolePage';
import RootResolver from '_common/pages/RootResolver';
import OrderPageFetcher from '_common/pages/order/OrderPageFetcher';
import LogoutPage from '_common/pages/LogoutPage';
import DeliveryInformationPage from '_common/pages/deliveryInformation/DeliveryInformationPage';
import CreateProducerPage from '_common/pages/create-role/CreateProducerPage';
import CreateConsumerPage from '_common/pages/create-role/CreateConsumerPage';
import CookiesInfoPage from '_common/pages/CookiesInfoPage';
import BillingAccountPage from '_common/pages/billing-account/BillingAccountPage';

export const consumerLayout = ConsumerLayout;

export const consumerRoutes: Routes = {
  '/': CustomerLandingPage,
  '/profile': ConsumerProfilePage,
  '/billing-account': BillingAccountPage,
  '/delivery-information': DeliveryInformationPage,
  '/producers': ProducerListPage,
  '/orders': ConsumerOrdersPage,
  '/orders/overview': ConsumerOrdersOverviewPage,
  '/orders/history': OrdersHistoryPage,
  '/orders/product-requests': ConsumerProductRequestsPage,
  '/orders/product-requests/:id': ConsumerProductRequestPage,
  '/orders/:secret': OrderPageFetcher,
  '/basket': CheckoutPage,
  '/switch-role': SwitchRolePage,
  '/favourites': FavoritesPage,
  '/onboarding/:step': OnboardingPage,
  '/login': LoginPage,
  '/logout': LogoutPage,
  '/cookies': CookiesInfoPage,
  '/user/verify/:token': VerifyUserEmail,
  '/create-consumer': CreateConsumerPage,
  '/create-producer': CreateProducerPage,
  '/:handle': RootResolver,
  '/producers/:handle': ProducerRedirect,
  '*': '/'
};
