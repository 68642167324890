import { createBrowserRouter, RouteObject } from 'react-router-dom';
import { Layout } from './components/layout';
import { unauthRoutes } from './routes/unauth';
import { producerRoutes } from './routes/producer';
import { consumerRoutes } from './routes/consumer';
import { Route } from './components/route';

const routeKeys = () => {
  const allRoutes = [
    ...Object.keys(unauthRoutes),
    ...Object.keys(producerRoutes),
    ...Object.keys(producerRoutes),
    ...Object.keys(consumerRoutes)
  ];
  const uniqueRoutes = Array.from(new Set(allRoutes));
  return uniqueRoutes;
};

const routes = () => {
  return routeKeys().map(route => {
    const routeObject = {
      index: route === '/',
      path: route === '/' ? undefined : route,
      element: (
        <Route
          unauth={unauthRoutes[route]}
          auth={unauthRoutes[route]}
          producer={producerRoutes[route]}
          consumer={consumerRoutes[route]}
        />
      )
    } satisfies RouteObject;
    return routeObject;
  });
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: routes()
  }
]);
