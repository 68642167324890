import { useFormContext, useWatch } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { Button, Icon } from '@dagens/carrot';
import { AddCustomerStartFormValues } from '.';

type Props = {
  onSubmit: (data: AddCustomerStartFormValues) => void;
};

export const AddCustomerStartNextButton = ({ onSubmit }: Props) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext<AddCustomerStartFormValues>();
  const organization = useWatch<AddCustomerStartFormValues>();
  const isValid =
    organization.organization && typeof organization.organization !== 'string';

  return (
    <Button
      type="submit"
      variant="primary"
      onClick={handleSubmit(onSubmit)}
      disabled={!isValid}
    >
      {t('producer:AddCustomer.continueCreation')}
      <Icon icon="forward" size="small" />
    </Button>
  );
};
