import { Address } from 'types/BillingAccount';
import { type Favorite } from 'types/Product';
import { type SanityDoc, type SanityRef } from 'types/Sanity';

export type Consumer = {
  name: string;
  type: string;
  contactPerson: string;
  emails: string[];
  phone: string;
  structuredDeliveryAddress: Partial<Address>;
  distributionArea: SanityRef;
  deliveryAddress: string;
  deliveryInfo?: string;
  deliveryWindow: DeliveryWindow;
  createdBy: string;
  createdByAccount: string;
} & SanityDoc<'consumers'>;

export type ConsumerFields = {
  favorites: Favorite[];
  betaTester?: boolean;
  features?: string[];
  isApprovedToOrder: boolean;
  deliveryAddress?: string;
  deliveryInfo?: string;
  deliveryWindow: DeliveryWindow;
  distributionArea?: SanityRef;
  distributionAreaId: string;
  distributionAreaName?: string;
};

export const VALID_TIME_SLOTS = [
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00'
] as const;

export type ValidTimeSlot = (typeof VALID_TIME_SLOTS)[number];

export type DeliveryWindow = {
  start: ValidTimeSlot;
  end: ValidTimeSlot;
};
