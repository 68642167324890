import { useCallback, useState } from 'react';
import REQ, { ReqType } from '../../../../utils/REQ';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import { postProducerCreateCustomer } from '../../../../api';
import { AddCustomerDeliveryFormValues } from '.';

const formValuesToCustomer = (
  formValues: AddCustomerDeliveryFormValues,
  userId: string
) => {
  if (
    !formValues.organization ||
    !formValues.organization.name ||
    !formValues.organization.orgNumber ||
    !formValues.organization.address ||
    !formValues.organization.isNotVatRegistered ||
    !formValues.billingName ||
    !formValues.primaryEmail ||
    !formValues.reminderEmail ||
    !formValues.customerName ||
    !formValues.contactPersonName ||
    !formValues.contactPhone ||
    !formValues.notificationEmail ||
    !formValues.deliveryAddress ||
    !formValues.deliveryWindowFrom ||
    !formValues.deliveryWindowTo
  ) {
    return null;
  }

  return {
    createdBy: userId,
    organizationName: formValues.organization?.name,
    organizationNumber: formValues.organization?.orgNumber,
    organizationAddress: formValues.organization?.address,
    isVatRegistered: !formValues.organization?.isNotVatRegistered,
    billingAccountName: formValues.billingName,
    billingDepartmentName: formValues.department || undefined,
    billingEanNumber: formValues.eanNumber || undefined,
    billingPrimaryInvoiceEmail: formValues.primaryEmail,
    billingReminderInvoiceEmail: formValues.reminderEmail,
    contactInformationCustomerName: formValues.customerName,
    contactInformationContactPersonFullName: formValues.contactPersonName,
    contactInformationContactPhone: formValues.contactPhone,
    contactInformationNotificationEmail: formValues.notificationEmail,
    deliveryAddress: formValues.deliveryAddress,
    deliveryWindow: {
      start: formValues.deliveryWindowFrom,
      end: formValues.deliveryWindowTo
    },
    deliveryInstructions: formValues.deliveryInstructions || undefined
  };
};

// Navigate to next step and pass the form value
export const useOnSave = () => {
  const [saveReq, setSaveReq] = useState<ReqType>(REQ.INIT);
  const { producerId, userId } = useAppSelector(({ auth }) => {
    return { producerId: auth._id, userId: auth.uid };
  });

  const onSave = useCallback(async (data: AddCustomerDeliveryFormValues) => {
    setSaveReq(REQ.PENDING);
    if (!producerId || !userId) {
      setSaveReq(REQ.ERROR);
      return;
    }

    try {
      const customer = formValuesToCustomer(data, userId);
      if (!customer) {
        setSaveReq(REQ.ERROR);
        return;
      }
      await postProducerCreateCustomer(producerId, customer);
      setSaveReq(REQ.SUCCESS);
    } catch (error) {
      setSaveReq(REQ.ERROR);
    }
  }, []);

  return { onSave, saveReq };
};
