import { Form, formFieldFactory, useWatch } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { PropsWithChildren, useState } from 'react';
import { FormField as CarrotFormField } from '@dagens/carrot';
import { getOrganization } from '../../../../api';
import { AddCustomerStartFormValues } from '.';

const { SearchBackground } = formFieldFactory<AddCustomerStartFormValues>();

// When we get a hit on the organization number, show different text
// depending on if the organization already exists or not
const FeedbackText = () => {
  const { t } = useTranslation();
  const { organization } = useWatch<AddCustomerStartFormValues>();
  if (!organization || typeof organization === 'string') {
    return undefined;
  }

  if (organization.exists) {
    return (
      <Form.FeedbackAlert>
        {t('signup:validation.organizationExists', {
          orgNumber: organization.orgNumber
        })}
      </Form.FeedbackAlert>
    );
  }

  return t('signup:validation.foundOrganization', {
    name: organization.name
  });
};

// Check if we should display an error message
// The error message should not be displayed while loading, or if the organization is found,
// or if there is no search string ('' or undefined)
// If the organization is not found, the organization value is either a string or null
const useShouldDisplayErrorMessage = <T,>(
  organization: T | string | null | undefined,
  loading: boolean
) => {
  if (loading) {
    return false;
  }
  if (organization === null) {
    return true;
  }
  if (typeof organization === 'string' && organization.length > 0) {
    return true;
  }
  return false;
};

type CustomFieldProps = PropsWithChildren<{
  loading: boolean;
}>;

// Custom form field that doesn't show errors while loading
const CustomField = ({ loading, children }: CustomFieldProps) => {
  const { t } = useTranslation();
  const { organization } = useWatch<AddCustomerStartFormValues>();
  const showError = useShouldDisplayErrorMessage(organization, loading);

  const errorMessage = showError
    ? t('signup:CouldNotFindOrgNumberInBusinessRegistry', {
        orgNumber: organization
      })
    : undefined;

  return (
    <CarrotFormField
      label={t('signup:orgNumberLabelText')}
      helpText={t('signup:orgNumberPlaceholder')}
      feedback={<FeedbackText />}
      errorMessage={errorMessage}
    >
      {children}
    </CarrotFormField>
  );
};

// On change search for the organization in the business registry
// If the organization is not found, the underlying object value should be null
const onChange = async (value: string | null) => {
  if (!value) {
    return null;
  }
  const response = await getOrganization({ orgNumber: value });
  return response.status === 'ORGANIZATION_NOT_FOUND' ? null : response;
};

// Search input that fetches organization data from the business registry
// and sets the organization value in the form
// The organization value is a string if there are no hits (or null if the input is empty)
export const OrganizationSearch = () => {
  const [loading, setLoading] = useState(false);

  return (
    <CustomField loading={loading}>
      <SearchBackground
        name="organization"
        displayValue={v => (typeof v === 'string' ? v : v?.orgNumber)}
        onChange={onChange}
        onLoadingChange={setLoading}
        debounce={1000}
      />
    </CustomField>
  );
};
