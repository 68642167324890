import { Navigate, useLocation } from 'react-router-dom';
import { Box, Link } from '@dagens/carrot';
import { useState } from 'react';
import { useTranslation } from '@dagens/frontend-i18n';
import { Form } from '@dagens/frontend-forms';
import { createPortal } from 'react-dom';
import { Page } from '../../../../components/page';
import { CommonNavbar } from '../../../../components/navbar';
import PageHeader from '../../../../components/page-header/PageHeader';
import { AddCustomerContactFormValues } from '../review-contact-information';
import { StructuredAddress } from '../../../../types/Logistics';
import { ValidTimeSlot } from '../../../../types/Consumer';
import { useOnSave } from './use-on-save';
import { AddCustomerContactNextButton as Next } from './next-button';
import { AddCustomerDeliveryInstructions as Instructions } from './delivery-instructions';
import { AddCustomerDeliveryWindow as Window } from './delivery-window';
import { AddCustomerDeliveryAddress as Address } from './delivery-address';

export type AddCustomerDeliveryFormValues = AddCustomerContactFormValues & {
  deliveryAddress: StructuredAddress | null;
  deliveryWindowFrom: ValidTimeSlot;
  deliveryWindowTo: ValidTimeSlot;
  deliveryInstructions: string;
};

export const AddCustomerDeliveryPage = () => {
  const { t } = useTranslation();
  const [bottomSheetRef, setBottomSheetRef] = useState<HTMLDivElement | null>(
    null
  );
  const { onSave, saveReq } = useOnSave();
  const location = useLocation();
  const stateValue = location.state as AddCustomerDeliveryFormValues;

  if (
    !stateValue.organization ||
    !stateValue.billingName ||
    !stateValue.primaryEmail ||
    !stateValue.reminderEmail ||
    !stateValue.customerName ||
    !stateValue.contactPersonName ||
    !stateValue.contactPhone ||
    !stateValue.notificationEmail
  ) {
    return <Navigate to="/customers/add/start" />;
  }

  return (
    <Page
      header={
        <>
          <CommonNavbar />
          <Box.FullWidth>
            <PageHeader
              headerText={t(
                'producer:AddCustomer.reviewDeliveryInformationPageHeader'
              )}
              annotation={`${t('producer:AddCustomer.headerAnnotation')} (4/4)`}
              subTitle={t(
                'producer:AddCustomer.reviewDeliveryInformationPageDescription'
              )}
            />
          </Box.FullWidth>
        </>
      }
      bottom={
        <Box.BottomSheet border ref={setBottomSheetRef}>
          <Link
            size="regular"
            variant="borderless"
            color="error"
            to="/customers"
          >
            {t('common:Cancel')}
          </Link>
        </Box.BottomSheet>
      }
    >
      <Form<AddCustomerDeliveryFormValues>
        onSubmit={onSave}
        defaultValue={stateValue}
      >
        <Form.Section>
          <Address />
          <Window />
          <Instructions />
        </Form.Section>

        {bottomSheetRef &&
          createPortal(
            <Next onSubmit={onSave} saveReq={saveReq} />,
            bottomSheetRef
          )}
      </Form>
    </Page>
  );
};
