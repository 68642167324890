import { Form, formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { Icon } from '@dagens/carrot';
import { validateEmail } from '../../../../utils/validation';
import { isValidEan } from '../../../../utils/billingAccount';
import { AddCustomerBillingFormValues } from '.';

const { Field, Input } = formFieldFactory<AddCustomerBillingFormValues>();

type Props = {
  defaultValue: Partial<AddCustomerBillingFormValues>;
};

export const AddCustomerBillingFields = ({
  defaultValue: { organization, billingName, primaryEmail }
}: Props) => {
  const { t } = useTranslation();
  return (
    <Form.Section>
      <Field
        to="billingName"
        label={t('producer:AddCustomer.reviewBillingInformationFieldLabel')}
        helpText={t(
          'producer:AddCustomer:reviewBillingInformationFieldDescription'
        )}
        feedback={
          billingName && (
            <>
              <Icon color="warning" icon="alert" />
              {t('producer:AddCustomer:retrievedFromCvrRegistry')}
            </>
          )
        }
        required
      >
        <Input name="billingName" />
      </Field>

      <Field
        to="department"
        label={t(
          'producer:AddCustomer.reviewBillingInformationFieldDepartmentNameLabel'
        )}
        helpText={t(
          'producer:AddCustomer:reviewBillingInformationFieldDepartmentNameDescription'
        )}
      >
        <Input name="department" />
      </Field>

      {organization?.country === 'dk' && (
        <Field
          to="eanNumber"
          label="EAN nummer"
          helpText={t('common:EanNumberExplanation')}
          maxLength={13}
        >
          <Input
            name="eanNumber"
            placeholder={t('common:EanNumberInputPlaceholder')}
            validate={value => {
              const valid = value !== null && isValidEan(value);
              return valid || t('common:EanNumberErrorMessage');
            }}
          />
        </Field>
      )}

      <Field
        to="primaryEmail"
        label={t(
          'producer:AddCustomer:reviewBillingInformationFieldPrimaryInvoiceLabel'
        )}
        feedback={
          primaryEmail && (
            <>
              <Icon color="warning" icon="alert" />
              {t('producer:AddCustomer:retrievedFromCvrRegistry')}
            </>
          )
        }
        required
      >
        <Input
          name="primaryEmail"
          validate={value => {
            const valid = value !== null && validateEmail(value);
            return valid;
          }}
        />
      </Field>

      <Field
        to="reminderEmail"
        label={t(
          'producer:AddCustomer:reviewBillingInformationFieldReminderInvoiceLabel'
        )}
        helpText={t(
          'producer:AddCustomer.reviewBillingInformationFieldReminderInvoiceDescription'
        )}
        required
      >
        <Input
          name="reminderEmail"
          validate={value => {
            const valid = value !== null && validateEmail(value);
            return valid;
          }}
        />
      </Field>
    </Form.Section>
  );
};
