import { Form, formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { Icon } from '@dagens/carrot';
import { validateEmail } from '../../../../utils/validation';
import { AddCustomerContactFormValues } from '.';

const { Field, Input } = formFieldFactory<AddCustomerContactFormValues>();

type Props = {
  defaultValue: Partial<AddCustomerContactFormValues>;
};

export const AddCustomerContactFields = ({ defaultValue }: Props) => {
  const { t } = useTranslation();
  return (
    <Form.Section>
      <Field
        to="customerName"
        label={t(
          'producer:AddCustomer.reviewContactInformationFieldCustomerNameLabel'
        )}
        helpText={t(
          'producer:AddCustomer:reviewContactInformationFieldCustomerNameDescription'
        )}
        feedback={
          defaultValue.customerName && (
            <>
              <Icon color="warning" icon="alert" />
              {t('producer:AddCustomer:retrievedFromCvrRegistry')}
            </>
          )
        }
        required
      >
        <Input name="customerName" />
      </Field>

      <Field
        to="contactPersonName"
        label={t(
          'producer:AddCustomer.reviewContactInformationFieldContactPersonFullNameLabel'
        )}
        required
      >
        <Input name="contactPersonName" />
      </Field>

      <Field
        to="contactPhone"
        label={t(
          'producer:AddCustomer.reviewContactInformationFieldContactPersonPhoneLabel'
        )}
        required
      >
        <Input name="contactPhone" />
      </Field>

      <Field
        to="notificationEmail"
        label={t(
          'producer:AddCustomer.reviewContactInformationFieldNotificationEmailLabel'
        )}
        helpText={t(
          'producer:AddCustomer:reviewContactInformationFieldNotificationEmailDescription'
        )}
        required
      >
        <Input
          name="notificationEmail"
          validate={value => {
            const valid = value !== null && validateEmail(value);
            return valid;
          }}
        />
      </Field>
    </Form.Section>
  );
};
