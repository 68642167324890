import { Routes } from '../types';
import { ProducerRedirect } from '../components/producer-redirect';
import CookiesInfoPage from '_common/pages/CookiesInfoPage';
import LogoutPage from '_common/pages/LogoutPage';
import ResetPasswordPage from '_common/pages/ResetPasswordPage';
import RootResolver from '_common/pages/RootResolver';
import VerifyUserEmail from '_common/pages/VerifyUserEmail';
import Login from '_unauthenticated/pages/login/Login';
import Signup from '_unauthenticated/pages/signup/Signup';
import SignupConsumers from '_unauthenticated/pages/signup/SignupConsumers';
import SignupProducers from '_unauthenticated/pages/signup/SignupProducers';

export const unauthRoutes: Routes = {
  '/': '/login',
  '/login': Login,
  '/logout': LogoutPage,
  '/signup': Signup,
  '/reset-password': ResetPasswordPage,
  '/cookies': CookiesInfoPage,
  '/user/verify/:token': VerifyUserEmail,
  '/buy': SignupConsumers,
  '/sell': SignupProducers,
  '/create-consumer': '/buy',
  '/letsgo': '/buy',
  '/komigang': '/buy',
  '/create-producer': '/sell',
  '/signup-producers': '/sell',
  '/blimed': '/sell',
  '/:handle': RootResolver,
  '/producers/:handle': ProducerRedirect,
  '*': '/'
};
