import { useWatch } from '@dagens/frontend-forms';
import { ContactCard, FormField } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { AddCustomerStartFormValues } from '.';

export const AddCustomerStartResults = () => {
  const { t } = useTranslation();
  const { organization } = useWatch<AddCustomerStartFormValues>();
  if (!organization || typeof organization === 'string') {
    return null;
  }

  return (
    <FormField
      label={t('producer:AddCustomer.informationRegistered')}
      helpText={t('producer:AddCustomer.informationConfirmation')}
    >
      <ul>
        <li>
          <ContactCard
            title={organization.name ?? ''}
            details={{
              [t('common:phoneNumber')]: organization.phone ?? '',
              [t('common:Address')]: organization.address ?? ''
            }}
          />
        </li>
      </ul>
    </FormField>
  );
};
