import { useFormContext } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { Button, Icon } from '@dagens/carrot';
import { AddCustomerContactFormValues } from '.';

type Props = {
  onSubmit: (data: AddCustomerContactFormValues) => void;
};

export const AddCustomerContactNextButton = ({ onSubmit }: Props) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext<AddCustomerContactFormValues>();
  return (
    <Button type="submit" variant="primary" onClick={handleSubmit(onSubmit)}>
      {t(
        'producer:AddCustomer.reviewDeliveryInformationButtonCreateCustomerText'
      )}
      <Icon icon="forward" size="small" />
    </Button>
  );
};
