import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddCustomerBillingFormValues } from '.';

// Navigate to next step and pass the form value
export const useOnSave = () => {
  const navigate = useNavigate();

  const onSave = useCallback(async (data: AddCustomerBillingFormValues) => {
    navigate('/customers/add/review-contact-information', {
      state: data
    });
  }, []);

  return { onSave };
};
