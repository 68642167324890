import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddCustomerStartFormValues } from '.';

// Navigate to next step and pass the organization value
export const useOnSave = () => {
  const navigate = useNavigate();

  const onSave = useCallback(async (data: AddCustomerStartFormValues) => {
    navigate('/customers/add/review-billing-information', {
      state: data
    });
  }, []);

  return { onSave };
};
