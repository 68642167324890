import { formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { AddCustomerDeliveryFormValues } from '.';

const { Field, Input } = formFieldFactory<AddCustomerDeliveryFormValues>();

export const AddCustomerDeliveryInstructions = () => {
  const { t } = useTranslation();
  return (
    <Field
      to="deliveryInstructions"
      label={t('common:deliveryInformationFormFieldDeliveryInstructionsLabel')}
      helpText={t(
        'common:deliveryInformationFormFieldDeliveryInstructionsDescription'
      )}
    >
      <Input name="deliveryInstructions" />
    </Field>
  );
};
