import { ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../_common/hooks/reduxHooks';
import { checkAuth } from '../../_common/reducers/auth';
import { RootState } from '../../store';

type Props = {
  unauth?: (() => ReactNode) | string;
  auth?: (() => ReactNode) | string;
  producer?: (() => ReactNode) | string;
  consumer?: (() => ReactNode) | string;
};

export const Route = ({
  unauth: Unauth,
  auth: Auth,
  producer: Producer,
  consumer: Consumer
}: Props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(checkAuth());
  }, []);
  const { authenticated, role, accountCount } = useAppSelector(
    ({ auth }: RootState) => {
      return {
        authenticated: auth.authenticated,
        role: auth._type,
        accountCount: auth.options?.length
      };
    }
  );

  if (authenticated === undefined) {
    return null;
  }

  if (role === 'producers' && Producer) {
    return typeof Producer === 'string' ? (
      <Navigate to={Producer} />
    ) : (
      <Producer />
    );
  }
  if (role === 'consumers' && Consumer) {
    return typeof Consumer === 'string' ? (
      <Navigate to={Consumer} />
    ) : (
      <Consumer />
    );
  }
  if (accountCount === 0 && Auth) {
    return typeof Auth === 'string' ? <Navigate to={Auth} /> : <Auth />;
  }
  if (Unauth) {
    return typeof Unauth === 'string' ? <Navigate to={Unauth} /> : <Unauth />;
  }

  return <Navigate to="/" />;
};
