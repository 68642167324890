import { Navigate, useLocation } from 'react-router-dom';
import { Box, Link } from '@dagens/carrot';
import { useState } from 'react';
import { useTranslation } from '@dagens/frontend-i18n';
import { Form } from '@dagens/frontend-forms';
import { createPortal } from 'react-dom';
import { Page } from '../../../../components/page';
import { CommonNavbar } from '../../../../components/navbar';
import PageHeader from '../../../../components/page-header/PageHeader';
import { AddCustomerStartFormValues } from '../start';
import { GetOrganizationFoundResponse } from '../../../../api';
import { useOnSave } from './use-on-save';
import { AddCustomerBillingNextButton as Next } from './next-button';
import { AddCustomerBillingFields as Fields } from './fields';

export type AddCustomerBillingFormValues = {
  organization: GetOrganizationFoundResponse | null;
  billingName: string | null;
  department: string | null;
  eanNumber: string | null;
  primaryEmail: string | null;
  reminderEmail: string | null;
};

export const AddCustomerBillingPage = () => {
  const { t } = useTranslation();
  const [bottomSheetRef, setBottomSheetRef] = useState<HTMLDivElement | null>(
    null
  );
  const { onSave } = useOnSave();
  const location = useLocation();
  const { organization } = location.state as AddCustomerStartFormValues;

  if (!organization || typeof organization === 'string') {
    return <Navigate to="/customers/add/start" />;
  }

  const defaultValue = {
    organization,
    billingName: organization.name,
    primaryEmail: organization.email
  };

  return (
    <Page
      header={
        <>
          <CommonNavbar />
          <Box.FullWidth>
            <PageHeader
              headerText={t(
                'producer:AddCustomer.reviewBillingInformationPageHeader'
              )}
              annotation={`${t('producer:AddCustomer.headerAnnotation')} (2/4)`}
              subTitle={t(
                'producer:AddCustomer.reviewBillingInformationPageDescription'
              )}
            />
          </Box.FullWidth>
        </>
      }
      bottom={
        <Box.BottomSheet border ref={setBottomSheetRef}>
          <Link
            size="regular"
            variant="borderless"
            color="error"
            to="/customers"
          >
            {t('common:Cancel')}
          </Link>
        </Box.BottomSheet>
      }
    >
      <Form<AddCustomerBillingFormValues>
        onSubmit={onSave}
        defaultValue={defaultValue}
      >
        <Fields defaultValue={defaultValue} />

        {bottomSheetRef &&
          createPortal(<Next onSubmit={onSave} />, bottomSheetRef)}
      </Form>
    </Page>
  );
};
