import { Navigate, useLocation } from 'react-router-dom';
import { Box, Link } from '@dagens/carrot';
import { useState } from 'react';
import { useTranslation } from '@dagens/frontend-i18n';
import { Form } from '@dagens/frontend-forms';
import { createPortal } from 'react-dom';
import { Page } from '../../../../components/page';
import { CommonNavbar } from '../../../../components/navbar';
import PageHeader from '../../../../components/page-header/PageHeader';
import { AddCustomerBillingFormValues } from '../review-billing-information';
import { useOnSave } from './use-on-save';
import { AddCustomerContactNextButton as Next } from './next-button';
import { AddCustomerContactFields as Fields } from './fields';

export type AddCustomerContactFormValues = AddCustomerBillingFormValues & {
  customerName: string;
  contactPersonName: string;
  contactPhone: string;
  notificationEmail: string;
};

export const AddCustomerContactPage = () => {
  const { t } = useTranslation();
  const [bottomSheetRef, setBottomSheetRef] = useState<HTMLDivElement | null>(
    null
  );
  const { onSave } = useOnSave();
  const location = useLocation();
  const stateValue = location.state as AddCustomerBillingFormValues;

  if (
    !stateValue.organization ||
    !stateValue.billingName ||
    !stateValue.primaryEmail ||
    !stateValue.reminderEmail
  ) {
    return <Navigate to="/customers/add/start" />;
  }

  const defaultValue = {
    ...stateValue,
    customerName: stateValue.organization.name,
    contactPhone: stateValue.organization.phone,
    notificationEmail: stateValue.organization.email
  };

  return (
    <Page
      header={
        <>
          <CommonNavbar />
          <Box.FullWidth>
            <PageHeader
              headerText={t(
                'producer:AddCustomer.reviewContactInformationPageHeader'
              )}
              annotation={`${t('producer:AddCustomer.headerAnnotation')} (3/4)`}
              subTitle={t(
                'producer:AddCustomer.reviewBillingInformationPageDescription'
              )}
            />
          </Box.FullWidth>
        </>
      }
      bottom={
        <Box.BottomSheet border ref={setBottomSheetRef}>
          <Link
            size="regular"
            variant="borderless"
            color="error"
            to="/customers"
          >
            {t('common:Cancel')}
          </Link>
        </Box.BottomSheet>
      }
    >
      <Form<AddCustomerContactFormValues>
        onSubmit={onSave}
        defaultValue={defaultValue}
      >
        <Fields defaultValue={defaultValue} />

        {bottomSheetRef &&
          createPortal(<Next onSubmit={onSave} />, bottomSheetRef)}
      </Form>
    </Page>
  );
};
