import { ForwardedRef, forwardRef } from 'react';
import { tv } from '../../../utils/tv';

import { ButtonComponentInternal, ButtonComponentProps } from '../components';
import { ButtonStyleProps } from '../styles';

export const modalActionStyle = tv({
  base: 'rounded-none',
  variants: {
    close: {
      true: `
        rounded-b
        border-x-0
        !border-t-thin
        border-b-0
        border-solid
        border-t-lightGrey
      `
    }
  }
});

type Props = Omit<
  ButtonComponentProps,
  ButtonStyleProps | 'className' | 'type'
> & {
  close?: boolean;
};

const Component = (
  { close, ...props }: Props,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  return (
    <ButtonComponentInternal
      {...props}
      ref={ref}
      variant="borderless"
      size="regular"
      className={modalActionStyle({ close })}
    />
  );
};

export const ModalAction = forwardRef(Component);
