import { useTranslation } from '@dagens/frontend-i18n';
import { Navigate, useLocation } from 'react-router-dom';
import { Box, Link } from '@dagens/carrot';
import { Form } from '@dagens/frontend-forms';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { GetOrganizationFoundResponse } from '../../../../api';
import {
  FeatureFlags,
  hasUserFeatureFlag
} from '../../../../services/FeatureFlagService';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import { AddCustomerStartResults } from './results';
import { OrganizationSearch } from './organization-search';
import { useOnSave } from './use-on-save';
import { AddCustomerStartNextButton as Next } from './next-button';
import { CommonNavbar } from '@components/navbar';
import { Page } from '@components/page';
import PageHeader from '@components/page-header/PageHeader';

export type AddCustomerStartFormValues = {
  organization: GetOrganizationFoundResponse | string | null;
};

export const AddCustomerStartPage = () => {
  const { state } = useLocation();
  const { onSave } = useOnSave();
  const { t } = useTranslation();
  const [bottomSheetRef, setBottomSheetRef] = useState<HTMLDivElement | null>(
    null
  );
  const { featureFlags } = useAppSelector(({ auth }) => {
    return { featureFlags: auth.features };
  });

  if (!hasUserFeatureFlag(FeatureFlags.ADD_CUSTOMER_FLOW, featureFlags)) {
    return <Navigate to="/customers" />;
  }

  return (
    <Page
      header={
        <>
          <CommonNavbar />
          <Box.FullWidth>
            <PageHeader
              headerText={t('producer:AddCustomer.startPageHeader')}
              annotation={`${t('producer:AddCustomer.headerAnnotation')} (1/4)`}
            />
          </Box.FullWidth>
        </>
      }
      bottom={
        <Box.BottomSheet border ref={setBottomSheetRef}>
          <Link
            size="regular"
            variant="borderless"
            color="error"
            to="/customers"
          >
            {t('common:Cancel')}
          </Link>
        </Box.BottomSheet>
      }
    >
      <Form<AddCustomerStartFormValues> onSubmit={onSave} defaultValue={state}>
        <Form.Section>
          <OrganizationSearch />
          <AddCustomerStartResults />
        </Form.Section>

        {bottomSheetRef &&
          createPortal(<Next onSubmit={onSave} />, bottomSheetRef)}
      </Form>
    </Page>
  );
};
