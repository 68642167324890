import {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactNode
} from 'react';
import { inputStyle } from '../styles';
import { InputContext } from '../utils/context';

type HTMLInputProps = InputHTMLAttributes<HTMLInputElement>;

type InputTypes = Extract<
  HTMLInputProps['type'],
  'text' | 'email' | 'password' | 'tel'
>;

export type InputProps = {
  type?: InputTypes;
  placeholder?: HTMLInputProps['placeholder'];
  maxLength?: HTMLInputProps['maxLength'];
  error?: boolean;
  value: string;
  onChange: HTMLInputProps['onChange'];
  before?: ReactNode;
  after?: ReactNode;
};

const Component = (
  { error, before, after, ...props }: InputProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const { container, input } = inputStyle({ error });
  return (
    <InputContext.Provider value={{ onChange: props.onChange }}>
      <label className={container()}>
        {before}
        <input {...props} ref={ref} className={input()} />
        {after}
      </label>
    </InputContext.Provider>
  );
};

export const Input = forwardRef(Component);
